<template>
  <div class="app">
    <transition name="fade">
      <div class="wrapper" v-if="!isLoad">
        <transition-group
          :name="isPageBack ? 'page-back' : 'page-next'"
          tag="div"
          class="content"
        >
          <component :is="page.component" :key="page.id" class="inner" />
        </transition-group>
        <Navbar />
      </div>
    </transition>

    <transition name="fade">
      <div v-if="isLoad" class="loader">
        <div class="title">
          <svg width="216" height="103" viewBox="0 0 216 103" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.8709 102.399C28.8881 102.399 26.1635 101.691 23.697 100.275C21.2305 98.8304 19.2803 96.8797 17.8463 94.4233C16.4409 91.938 15.7383 89.1927 15.7383 86.1872C15.7383 83.1818 16.4409 80.4508 17.8463 77.9945C19.2803 75.5092 21.2305 73.5585 23.697 72.1425C26.1635 70.6976 28.8881 69.9751 31.8709 69.9751C36.5171 69.9751 40.3602 71.6657 43.4003 75.0468L39.3134 79.0348C37.3345 76.694 34.868 75.5236 31.9139 75.5236C29.9923 75.5236 28.2428 75.9716 26.6654 76.8674C25.088 77.7344 23.8404 78.977 22.9227 80.5953C22.0336 82.2137 21.589 84.0776 21.589 86.1872C21.589 88.2968 22.0336 90.1608 22.9227 91.7791C23.8404 93.3974 25.088 94.6545 26.6654 95.5504C28.2428 96.4173 29.9923 96.8508 31.9139 96.8508C35.1548 96.8508 37.865 95.4926 40.0447 92.7761L44.1747 96.7208C42.6546 98.5703 40.8478 99.9863 38.7541 100.969C36.6892 101.923 34.3947 102.399 31.8709 102.399Z" fill="white"/>
            <path d="M58.4346 102.399C56.1688 102.399 54.1469 101.894 52.3687 100.882C50.5905 99.8418 49.1995 98.4402 48.1957 96.6774C47.2206 94.8857 46.733 92.8773 46.733 90.6521C46.733 88.4558 47.2206 86.4618 48.1957 84.67C49.1995 82.8783 50.5905 81.4767 52.3687 80.4653C54.1469 79.4249 56.1688 78.9048 58.4346 78.9048C60.6716 78.9048 62.6792 79.4249 64.4574 80.4653C66.2356 81.4767 67.6122 82.8783 68.5873 84.67C69.5911 86.4618 70.0931 88.4558 70.0931 90.6521C70.0931 92.8773 69.5911 94.8857 68.5873 96.6774C67.6122 98.4402 66.2356 99.8418 64.4574 100.882C62.6792 101.894 60.6716 102.399 58.4346 102.399ZM58.4346 97.1542C59.5244 97.1542 60.5282 96.8942 61.446 96.374C62.3637 95.8538 63.0951 95.1024 63.64 94.1199C64.1849 93.1373 64.4574 91.9814 64.4574 90.6521C64.4574 89.3516 64.1849 88.2101 63.64 87.2276C63.0951 86.245 62.3637 85.4937 61.446 84.9735C60.5282 84.4533 59.5244 84.1932 58.4346 84.1932C57.3447 84.1932 56.3409 84.4533 55.4231 84.9735C54.5054 85.4937 53.7597 86.245 53.1861 87.2276C52.6412 88.2101 52.3687 89.3516 52.3687 90.6521C52.3687 91.9525 52.6412 93.1084 53.1861 94.1199C53.7597 95.1024 54.5054 95.8538 55.4231 96.374C56.3409 96.8942 57.3447 97.1542 58.4346 97.1542Z" fill="white"/>
            <path d="M82.1108 102.399C79.4723 102.399 77.4646 101.576 76.088 99.9285C74.7113 98.2813 74.023 95.9839 74.023 93.0362V79.5983H79.6587V92.3426C79.6587 93.8454 80.0172 95.0302 80.7342 95.8972C81.4512 96.7352 82.3976 97.1542 83.5735 97.1542C85.1796 97.1542 86.4559 96.5618 87.4023 95.377C88.3488 94.1921 88.822 92.6894 88.822 90.8688V79.5983H94.4577V101.706H89.1662V98.9315H88.822C88.1624 100.001 87.2302 100.853 86.0257 101.489C84.8498 102.096 83.5448 102.399 82.1108 102.399Z" fill="white"/>
            <path d="M99.9485 79.5983H105.24V82.546H105.584C106.129 81.5056 106.975 80.6531 108.122 79.9885C109.27 79.2949 110.531 78.9481 111.908 78.9481C112.912 78.9481 113.83 79.1071 114.661 79.4249V85.1035C113.887 84.8145 113.227 84.6122 112.682 84.4966C112.166 84.3522 111.607 84.2799 111.005 84.2799C109.341 84.2799 108.022 84.8868 107.047 86.1005C106.072 87.3143 105.584 88.8459 105.584 90.6954V101.706H99.9485V79.5983Z" fill="white"/>
            <path d="M125.813 102.399C123.146 102.399 120.981 101.85 119.317 100.752C117.683 99.654 116.535 98.2235 115.876 96.4607L120.909 94.2499C121.368 95.2903 122.013 96.085 122.845 96.6341C123.705 97.1542 124.695 97.4143 125.813 97.4143C126.846 97.4143 127.706 97.2554 128.395 96.9375C129.083 96.5907 129.427 96.0416 129.427 95.2903C129.427 94.5678 129.112 94.0332 128.481 93.6864C127.85 93.3107 126.903 92.9784 125.641 92.6894L123.06 92.1259C121.282 91.6924 119.805 90.9121 118.629 89.7851C117.453 88.6292 116.865 87.1987 116.865 85.4937C116.865 84.2221 117.238 83.0806 117.984 82.0692C118.758 81.0577 119.791 80.2775 121.081 79.7284C122.4 79.1793 123.849 78.9048 125.426 78.9048C129.958 78.9048 132.983 80.5086 134.503 83.7164L129.685 85.8404C128.825 84.2799 127.448 83.4997 125.555 83.4997C124.58 83.4997 123.806 83.6875 123.232 84.0632C122.659 84.41 122.372 84.8579 122.372 85.407C122.372 86.4473 123.347 87.2276 125.297 87.7477L128.524 88.528C130.703 89.0771 132.338 89.9151 133.428 91.0422C134.547 92.1692 135.106 93.5564 135.106 95.2036C135.106 96.6196 134.69 97.8767 133.858 98.9749C133.055 100.044 131.937 100.882 130.503 101.489C129.097 102.096 127.534 102.399 125.813 102.399Z" fill="white"/>
            <path d="M138.978 79.5983H144.269V82.3726H144.613C145.302 81.3034 146.219 80.4653 147.367 79.8584C148.542 79.2227 149.847 78.9048 151.281 78.9048C152.83 78.9048 154.178 79.2805 155.325 80.0318C156.473 80.7543 157.29 81.679 157.777 82.8061C158.494 81.6501 159.47 80.7109 160.703 79.9885C161.965 79.266 163.442 78.9048 165.134 78.9048C167.629 78.9048 169.522 79.685 170.813 81.2456C172.132 82.7772 172.792 84.8579 172.792 87.4877V101.706H167.199V88.528C167.199 87.1409 166.869 86.0716 166.209 85.3203C165.578 84.5689 164.689 84.1932 163.542 84.1932C162.079 84.1932 160.904 84.7856 160.015 85.9705C159.154 87.1264 158.724 88.6581 158.724 90.5654V101.706H153.088V88.528C153.088 87.1409 152.744 86.0716 152.056 85.3203C151.396 84.5689 150.45 84.1932 149.216 84.1932C147.84 84.1932 146.721 84.7856 145.861 85.9705C145.029 87.1264 144.613 88.6581 144.613 90.5654V101.706H138.978V79.5983Z" fill="white"/>
            <path d="M188.457 102.399C186.277 102.399 184.312 101.894 182.563 100.882C180.813 99.8707 179.437 98.4836 178.433 96.7208C177.458 94.9291 176.97 92.9206 176.97 90.6954C176.97 88.5858 177.458 86.6351 178.433 84.8434C179.408 83.0228 180.756 81.5779 182.477 80.5086C184.198 79.4394 186.119 78.9048 188.242 78.9048C190.507 78.9048 192.458 79.396 194.092 80.3786C195.727 81.3612 196.96 82.7049 197.792 84.41C198.652 86.115 199.083 88.0078 199.083 90.0885C199.083 90.7532 199.04 91.389 198.954 91.9958H182.477C182.764 93.7587 183.452 95.1024 184.542 96.0272C185.66 96.9231 186.994 97.371 188.543 97.371C189.833 97.371 190.937 97.0964 191.855 96.5474C192.802 95.9694 193.547 95.2036 194.092 94.2499L198.652 96.504C196.415 100.434 193.017 102.399 188.457 102.399ZM193.662 87.9645C193.605 87.2709 193.347 86.5918 192.888 85.9271C192.458 85.2336 191.841 84.67 191.038 84.2366C190.263 83.7742 189.346 83.543 188.285 83.543C186.937 83.543 185.775 83.9476 184.8 84.7567C183.853 85.537 183.165 86.6063 182.735 87.9645H193.662Z" fill="white"/>
            <path d="M1 0.5H0.5V1V58.1846V58.6846H1H11.8542H12.3542V58.1846V34.5648H35.5552V58.1846V58.6846H36.0552H46.9094H47.4094V58.1846V1V0.5H46.9094H36.0552H35.5552V1V23.3419H12.3542V1V0.5H11.8542H1ZM55.2015 11.2229V11.7229H55.7015H71.2818V58.1846V58.6846H71.7818H82.6361H83.1361V58.1846V11.7229H98.7164H99.2164V11.2229V1V0.5H98.7164H55.7015H55.2015V1V11.2229ZM107.391 0.5H106.891V1V58.1846V58.6846H107.391H118.084H118.584V58.1846V29.9916V29.9747L118.583 29.9579L118.025 21.7107L132.332 58.3664L132.456 58.6846H132.798H141.24H141.582L141.706 58.3655L155.932 21.7177L155.374 29.9579L155.373 29.9747V29.9916V58.1846V58.6846H155.873H166.647H167.147V58.1846V1V0.5H166.647H152.577H152.232L152.109 0.822283L136.979 40.612L121.848 0.822283L121.726 0.5H121.381H107.391ZM179.784 0.5H179.284V1V58.1846V58.6846H179.784H215H215.5V58.1846V47.9616V47.4616H215H191.138V1V0.5H190.638H179.784Z" fill="#FFD141" stroke="#FFD141"/>
          </svg>
        </div>
        <Preloader />
      </div>
    </transition>

    <ModalMoreCourses />
    <Article />
    <Section />
    <ArticleSection />
    <Test />

    <Ads />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Ads from '@/components/ads/Ads'
import Preloader from "@/components/base/Preloader";
import ModalMoreCourses from "@/components/ModalMoreCourses";
import Navbar from "./components/base/Navbar";
import Home from "./views/Home";
import Articles from "./views/Articles";
import Article from "./components/article/Article";
import ArticleSection from "./components/article/ArticleSection";
import Section from "./components/article/Section";
import Tests from "./views/Tests";
import Test from "@/components/test/questions/Wrapper";

export default {
  name: "App",
  components: {
    Navbar,
    Home,
    Articles,
    Tests,
    Test,
    ModalMoreCourses,
    Preloader,
    Article,
    Section,
    ArticleSection,
    Ads
  },
  data() {
    return {
      isMoreCourses: true,
      isPageBack: false,
      prewPage: "home",
      isLoad: true,
    };
  },
  watch: {
    page(page) {
      this.isPageBack = page.id < this.prewPage;
      this.prewPage = page.id;
    },
  },
  computed: {
    ...mapState(["page"]),
  },
  methods: {
    ...mapActions(["initCourses"]),
  },
  async mounted() {
    await this.initCourses();
    this.isLoad = false;
    // setTimeout(async () => {
    //   await this.initCourses();
    //   this.isLoad = false;
    // }, 2000);
  },
};
</script>

<style lang="scss">
.app {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .wrapper > .content {
    position: absolute;
    width: 100%;
    height: calc(100% - var(--navbar-height));
    display: flex;
    flex: 1;
    overflow: hidden;

    .inner {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }

  & > .loader {
    background: var(--main);
    color: var(--white);
    font-size: 45px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.page-next-leave-active,
.page-next-enter-active {
  position: absolute;
  transition: 0.4s;
}
.page-next-enter {
  transform: translate(100%, 0);
}
.page-next-leave-to {
  transform: translate(-100%, 0);
}

.page-back-leave-active,
.page-back-enter-active {
  position: absolute;
  transition: 0.4s;
}
.page-back-enter {
  transform: translate(-100%, 0);
}
.page-back-leave-to {
  transform: translate(100%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
